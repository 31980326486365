.cc-app {
  height: 100%;
  text-align: center;
}

.cc-app-header {
  background-color: #f3ebd5;
  box-shadow: 0 1px 3px 2px rgba(0, 0, 0, 0.15);
}

.cc-app-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

.cc-app-logo {
  margin: 0;
  letter-spacing: -0.04em;
}

.cc-app-main {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.cc-app-title {
  font-size: 28px;
  margin-bottom: 10px;
}

.cc-app-text {
  font-size: 22px;
  margin: 0;
}